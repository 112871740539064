<template>
	<div>
		<div>
			<ul>
				<li
					v-for="(card, index) in item_info.cartl_nft_purchase_list"
					:key="'card_' + index"
					class="inline-block mb-10"
					:style="index % 4 == 3 ? '' : 'margin-right: 10px'"
				>
					<div
						style="width: 210px"
					>
						<img :src="card.cartl_nft_img_url" class="width-100" />
					</div>
				</li>
			</ul>
		</div>

		<hr class="under-line mt-30">

		<div class="mt-30">
			<div class=" flex-row justify-space-between">
				<span class="count">
					<b>BUY</b>
					<strong class="col_red"> {{ text_coin_quantity }} {{ item_info.sl_coin_token_name }}</strong>
				</span>
				<p class="color-gray">
					NFT 컬랙션에서 구입하신 {{  item_info.nft_card_div_name }}를 확인하실 수 있습니다.
				</p>
			</div>
			<div class=" flex-row justify-space-between mt-20">
				<div class=" ">
					<button class="btn-inline btn-primary-outline radius-20" @click="$router.push({ name: 'nft'})">NFT 목록</button>
				</div>
				<div class="">
					<button class="btn-inline btn-primary radius-20" @click="$router.push({name: 'drops'})">확인</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
export default {
	name: 'mafia0432'
	, props: ['user', 'item_result', 'item_card']
	, data: function () {
		return {
			lb: 'down'
		}
	}
	, computed: {
		item_info: function(){

			let t = this.item_result

			if(t){

				if (!t?.cartl_nft_purchase_list) {
					let d = []
					d.push({
						cartl_nft_img_url: t?.sl_img_url
					})
					this.$set(t, 'cartl_nft_purchase_list', d)
				}
			}else{
				t = {

				}
			}

			return t
		}
		, text_card_div_name: function(){
			let t = ''
			if(this.item_info.nft_card_div_name ){
				t = this.item_info.nft_card_div_name
			}else{
				t = 'MAFIA CARD'
			}
			return t
		}
		, text_coin_quantity: function(){
			let t = this.item_info.coin_token_quantity ? this.item_info.coin_token_quantity : this.item_info.tot_purchase_coin_token_quantity ? this.item_info.tot_purchase_coin_token_quantity: 0

			if(this.item_info.tot_payment_mthd_purchase_price){
				t = this.item_info.tot_payment_mthd_purchase_price
			}


			return t
		}
	}
	, methods: {

	}

	, created() {

	}
}
</script>

<style>
.down {
	bottom: 0;
	transition: all 1s
}

.up {
	bottom: -230px;
	transition: bottom 1s
}

.main_card_wrap::-webkit-scrollbar {
	width: 2px;

	background-color: #000;
}
.main_card_wrap::-webkit-scrollbar-thumb {
	background-color: #333;
}

.card-box {
	max-height: 880px;
	overflow-y: scroll;
	padding-bottom: 120px
}

.lb-wrap {
	position: fixed; left: 0; border: 1px solid rgba(255, 255, 255, 0.05); width: 100%; height: 264px; display: flex; flex-direction: row; justify-content: center; border-radius: 40px 40px 0 0; background-color: #181A39
}
.lb-trans {
	position: absolute; top: 14px; left: 50%; transform: translateX(-50%)
}
.lb-bg {
	width: 50px; height: 5px; background: #24264E; border-radius: 2.5px;
}
.lb-bottom-box {
	max-width: 320px; padding: 10px
}
</style>